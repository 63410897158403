<template>
  <bg-page>
    <div class="Box">
      <div class="limitBox">
        <div>
          <FirstTitle :myTitle="state.FirstTitle"></FirstTitle>
        </div>
        <div class="SearchBox">
          <div>
            <span class="iconfont iconsousuo"></span>
            <input
              v-model="state.title"
              placeholder="请输入关键词搜索"
              type="search"
            />
          </div>
          <div @click="Search">
            <span>搜索</span>
          </div>
        </div>
        <FilterType
          :data="state.FilterSearch"
          @GetTypeIndex="GetTypeIndex"
        ></FilterType>
        <red-divider />
        <!--企业list-->
        <div class="EnterpriseList">
          <div
            v-for="(item, index) in state.EnterpriseList"
            :key="index"
            class="EnterpriseItem"
            @click="GoToDetails(item)"
          >
            <div class="image1">
              <el-image
                v-if="item.img"
                :src="item.img"
                fit="cover"
                style="width: 100%; height: 180px"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div class="image2">
              <el-image
                v-if="item.logo"
                :src="item.logo"
                fit="cover"
                style="width: 100px; height: 100px"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
              <el-image
                v-else
                :src="require('../Home/img/logo.png')"
                fit="contain"
                style="
                  width: 100px;
                  height: 100px;
                  background-color: white !important;
                  padding: 10px;
                "
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div class="textName">
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
        <!--分页-->
        <Pagination
          :config="state.PageConfig"
          @handleCurrentPage="handleCurrentPage"
        ></Pagination>
      </div>
    </div>
  </bg-page>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import FirstTitle from "@/components/FirstTitle/FirstTitle";
import Pagination from "@/components/Pagination/Pagination";
import FilterType from "@/components/FilterType/FilterType";
import api from "@/comment/request/api";

export default {
  name: "Enterprise",
  components: { FirstTitle, Pagination, FilterType },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      FirstTitle: {
        ChinaName: "企业信息",
        EnglishName: "Enterprise Information",
      },
      FilterSearch: [
        {
          typeName: "企业类型",
          typeList: [
             {
              value: null,
              label: "全部",
            },
             {
              value: 0,
              label: "其他",
            },
            {
              value: 1,
              label: "上规上统",
            },
            {
              value: 2,
              label: "文化龙头",
            },
            {
              value: 3,
              label: "文化百强",
            },
            {
              value: 4,
              label: "三企入桂",
            },
            {
              value: 5,
              label: "小微企业",
            },
          ],
        },
        {
          typeName: "企业注册地",
          typeList: [],
        },
        {
          typeName: "企业性质",
          typeList: [
            {
              value: null,
              label: "全部",
            },
            {
              value: 0,
              label: "其他",
            },
            {
              value: 1,
              label: "国有(控股)企业",
            },
            {
              value: 2,
              label: "民营(控股)企业",
            },
            {
              value: 3,
              label: "集体企业",
            },
            {
              value: 4,
              label: "外资企业",
            },
            {
              value: 5,
              label: "事业单位",
            },
          ],
        },
        {
          typeName: "行业类别",
          typeList: [],
        },
        {
          typeName: "企业规模",
          typeList: [
            {
              value: null,
              label: "全部",
            },
            {
              value: 0,
              label: "10人以下",
            },
            {
              value: 1,
              label: "10-50人",
            },
            {
              value: 2,
              label: "50-200人",
            },
            {
              value: 3,
              label: "200-500人",
            },
            {
              value: 4,
              label: "500人以上",
            },
          ],
        },
      ],
      PageConfig: {
        currentPage: 1,
        total: 1,
        pageNum: 8,
      },
      EnterpriseList: [],
      title: null,
      type: null,
      cate_id: null,
      code: null,
      nature: null,
      scale: null,
    });
    // 类别
    api.GetEnterpriseCate().success((res) => {
      state.FilterSearch[3].typeList = [{value: null, label: '全部'}].concat(res.data.data);
    })
    // 地区
    api.GetRegionList().success((res) => {
      state.FilterSearch[1].typeList = [{value: null, label: '全部'}].concat(res.data.data);
    });
    const GetTypeIndex = (index, item) => {
      if (index == 0) state.type = item.value;
      if (index == 1) state.code = item.value;
      if (index == 2) state.nature = item.value;
      if (index == 3) state.cate_id = item.value;
      if (index == 4) state.scale = item.value;
      state.title = "";
      GetEnterpriseData();
    };
    // 分页
    const handleCurrentPage = (index) => {
      state.PageConfig.currentPage = index;
      GetEnterpriseData();
    };
    // 详情
    const GoToDetails = (item) => {
      router.push({
        name: "EnterpriseDetails",
        query: { id: item.enterprise_id },
      });
    };
    const GetEnterpriseData = () => {
      const params = {
        page: state.PageConfig.currentPage,
        limit: state.PageConfig.pageNum,
        cate_id: state.cate_id,
        title: state.title,
        code: state.code,
        type: state.type,
        nature: state.nature,
        scale: state.scale,
      };
      api.GetEnterpriseList(params).success((res) => {
        state.EnterpriseList = res.data.data.data;
        state.PageConfig.total = Number(res.data.data.total);
      });
    };
    GetEnterpriseData();
    // 搜索
    const Search = () => {
      GetEnterpriseData();
    };
    return {
      state,
      GetTypeIndex,
      handleCurrentPage,
      GoToDetails,
      Search,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

.Box {
  .SearchBox {
    margin-bottom: 10px;
  }

  .EnterpriseList {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    .EnterpriseItem {
      width: calc(100% / 4 - 30px);
      height: 300px;
      background-color: #ffffff;
      margin-bottom: 40px;
      position: relative;
      margin-right: 40px;
      text-align: center;

      &:nth-child(4n) {
        margin-right: 0;
      }
      .image1 {
        padding: 10px 10px;
      }

      .image2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -10%);

        /deep/ .el-image {
          border-radius: 50%;
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
        }
      }

      .textName {
        width: 100%;
        position: absolute;
        bottom: 20px;

        p {
          padding: 0 20px;
          text-align: center;
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            color: #7f0404;
          }
        }
      }
    }

    .EnterpriseItem:nth-child(4n) {
      margin-right: 0;
    }
  }

  .Pagination {
    margin-top: 0;
  }
}
</style>